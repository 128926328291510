/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
		init: function() {
        // JavaScript to be fired on all pages
		},
		finalize: function() {
			// JavaScript to be fired on all pages, after page specific JS is fired
			
			var affiliate = jQuery('#affiliate-carousel');
			affiliate.owlCarousel({
				margin: 140,
				loop: true,
				responsive: {
					0: {
					items: 1
					},
					768: {
					items: 2
					},
					992: {
					items: 3
					}
				}
			});
			var carousel = $('.category-carousel').owlCarousel({
				nav: true,
				responsive:{
					0:{
						items:1
					},
					768:{
						items:3
					}
				},
				onInitialized  : counter,
				onTranslated : counter
				}
			);
			
			function counter(event) {
				var element = event.target;         // DOM element, in this example .owl-carousel
				var total = $(event.target).find('.owl-dots > div').length;
				var items     = event.item.count;     // Number of items
				var item      = event.item.index + 1;     // Position of the current item
				
				$(event.target).find('.owl-nav .counter').remove();
				$(event.target).find('.owl-nav > .owl-prev').after('<div class="counter">'+item+"/"+total+'</div>');
			
			}
			
			$('.category-item').click(function(){
				var tab_id = $(this).attr('data-tab');
				var active_img = $(this).find('img').attr('data-image-active');
				var cat_image = $('.category-item.current').find('img').attr('data-image');
				
				if($(this).hasClass('current')){
					
				}else{
					$('.category-item.current').find('img').attr('src', cat_image);
					$(this).find('img').attr('src', active_img);
					
					$('.blog-row .category-item').removeClass('current');
					$('.category-tab').removeClass('current');

					$(this).addClass('current');
					$('.blog-posts-container '+tab_id).addClass('current');
				}
			});
			
			$('.cloud').each(function() {
				$(this).jqFloat({
					width: 100,	
					height: 0,
					speed: 3000
				});
			});
			
			$('#rocket').on('click', function() {
				if($(this).hasClass('landed')){
					$('.contact-wrap').slideUp(350);
					$( this ).delay(400).animate({
						top: '-=28px',
						easing: 'easeOutQuad'
					}, 1500, function() {
					}).removeClass('flip landed');
				}
				else {
					$( this ).toggleClass('flip landed').animate({
						top: '+=28px',
						easing: 'easeOutQuad'
					}, 1500, function() {
						$('.contact-wrap').slideDown(350);
					});
				}
			});
			
			$(window).on("load",function(e){
				var allPanels = $('.column-item .content-wrap');
				$('.column-item h3').click(function(e) {
					allPanels.slideUp();
					$( ".about-container .column-item" ).removeClass('active');
					$(this).next().slideDown();
					$(this).closest('.column-item').addClass('active');
				});
			});
			
			$('#signup-btn').on('click', function(e) {
				e.preventDefault();
				$('html, body').animate({
				  scrollTop: $('#newsletter').offset().top
				}, 1000, function() {
					$('#newsletter input[name=your-email]').focus();
				});
			});
			
			$('ul.tabs li').click(function() {
				$('ul.tabs li').removeClass('active');
				$(this).addClass('active');
				$('.tab-content').hide();
				var activeTab = $(this).find('a').attr('href');
				$(activeTab).fadeIn();
				$('html, body').animate({
					scrollTop: $(activeTab).offset().top
				}, 'slow');
				return false;
			});
			
			$(document).ready(function () {
				// Handler for .ready() called.
				$('html, body').animate({
					scrollTop: $('#tab-content').offset().top
				}, 'slow');
			});
		}
    },
    // Home page
    'home': {
		init: function() {
        // JavaScript to be fired on the home page
		},
		finalize: function() {
			// JavaScript to be fired on the home page, after the init JS
			$('#banner-slider').owlCarousel({
				loop:false,
				margin:0,
				autoHeight: false,
				nav:false,
				autoplay:false,
				autoplayTimeout:5000,
				autoplayHoverPause:true,
				responsive:{
					0:{
						items:1,
						dots: false
					},
					600:{
						items:1,
						dots: false
					},
					1000:{
						items:1,
						dots: false
					}
				}
			});
		}
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
		init: function() {
        // JavaScript to be fired on the about us page
		}
    },
	'page_template': {
		init: function() {
			// JavaScript to be fired on the healthy knowledge page
			$('#knowledge-slider').owlCarousel({
				loop:true,
				margin:0,
				nav:true,
				responsive:{
					0:{
						items:1,
						dots: false
					},
					600:{
						items:1,
						dots: false
					},
					1000:{
						items:1,
						dots: false
					}
				}
			});
		}
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.